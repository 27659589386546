
.drop-zone {
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.drop-zone .dropit-text{
    display:None

}

.dragging {
    z-index: 1;
    height: 100vh;
    background-color:rgba(226, 168, 80, 0.1);
    
}

.dragging div{
    filter:opacity(.5);
}

.dragging .dropit-text{
    display: block;
    position:absolute;
    text-shadow: 1px;
    filter:opacity(1);
    color: white;
    font-size: x-large;
    font-weight: bold;
}


