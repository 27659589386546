.PresetEditor {

    width: 100%; 
    /*margin: auto;
    height: auto;*/
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #303030;
    width: 500px;
    margin: auto;
    padding: 0px;
    border-radius: 20px;
    border: solid 1px #101010;

}

.PresetEditor .addmod {
    padding: 10px;
}

.PresetEditor .addmod h2, .PresetEditor .addmod p {
    margin: auto;
    padding: auto;
    height: 20px;
    text-align: center;
    vertical-align: auto;
}

.PresetEditor .presetNameWrap {
    display: flex;
    flex: row;
    height: 20px;
}

.PresetEditor .presetNameWrap .PresetName{
    border: solid 1px black;
    width : 450px;
    height: 20px;
    border-radius: 20px 0px 0  0;
    padding-left: 20px;
    background-color: #101010;
    color: white;
    border-right-width: 0px;
}

.PresetEditor .presetNameWrap img{
    border: solid 1px white;
    height: 20px;
    padding: 0;
    margin: 0;
    width: 50px;
    background-color: #101010;
    border-radius: 0 20px 0 0;
    border-left-width: 0px;

}

.PresetEditor .addmod-modeSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    margin:auto;
    
}
.PresetEditor .addmod-modeSelector h3{
    width :100px;
    
    height: 30px;
    text-align: center;
    background-color: #202020;
    font-size: medium;
    vertical-align: middle;
    cursor: pointer;

}
.PresetEditor .addmod-modeSelector .steamSearch {
    
    border: solid 0px black;
    border-radius: 14px 0px 0px 14px;
    border-width: 1px 0px 1px 1px;
}
.PresetEditor .addmod-modeSelector .steamLink{
    border: solid 1px black;
}
.PresetEditor .addmod-modeSelector .steamColection{
    border: solid 0px black;
    border-width: 1px 1px 1px 0px;
    border-radius:  0px 14px 14px 0px;
    
}



.presetEditor, .SteamSearch{
    margin: 0px;
    margin: 10px;
    padding: 10px;
    background-color: #202020;
    border-radius: 20px;
    width: 500px;
    border: solid 1px black;
}

.searchBox {
    justify-content: center;
    display:flex;
    border-radius: 20px;
}

.searchBox input{
    
    border: solid 1px black;
    
    background-color: #30303000;
    width: 90%; 
    height: 20px;
    padding: 5px;
    border-radius: 20px 0 0 20px;
    color: whitesmoke;
    border-width: 1px 0px 1px 1px ;
}

.searchBox img{
    border: solid 0px black;

    border-width: 1px 1px 1px 0px ;    
    height: 20px;
    width: 10%;
    height: 20px;
    padding: 5px;
    border-radius: 0 20px 20px 0;
    
}

.searchBox input:focus-visible + .searchBox img{
    background-color: red;    
}

.searchBox img:hover {
    filter: grayscale();
    background-color: black;
}


.presetEditor .presetnameInput{
    border: solid 1px black;
    
    background-color: #30303000;
    width: 100%; 
    height: 20px;
    padding: 5px;
    border-radius: 20px 20px 20px 20px;
    color: whitesmoke;
    border-width: 1px 1px 1px 1px ;
}

.PresetEditor .a3mod {
    cursor:default;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: solid 1px #101010;    
    border-width: 0px 0px 1px 0px;
}
.PresetEditor .a3mod a{
    color: whitesmoke;
    text-decoration: None;
    width: 75%;
}
.PresetEditor .a3mod p {
    width: 15%;
    height: 100%;   
    filter:brightness(1.5)
    
}
.PresetEditor .a3mod img {
    width: 10%;
    filter: grayscale();
    margin:auto;
    height: 60%;
    padding: 5px;
    cursor: pointer;

}
.PresetEditor .a3mod img:hover {
    border-radius: 20px;
    background-color: #101010;
    filter: none;
}
.PresetEditor .a3mod img:hover + .a3mod{
    background-color: red;
    filter: none;
}