.compareAPP {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    justify-content: center;   
    
}
/* infopanel */


.compareAPP .infopanel {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
}


.compareAPP .infopanel button{
    font-weight:bold;
    color: black;
    background-color: #E2A750;
    border-radius: 5px;
    height: 40px;
    width: 200px;
    margin: auto;
    border-width: 0px;
    
}


.compareAPP .infopanel button:hover {
    background-color:#ffc060 ;
    color: black;
}
.compareAPP .infopanel button:active {
    background-color: black;
    color: #E2A750;
}

.compareAPP h1{

    display: flex;
    margin: auto;
    padding: 36px;
}

.compareAPP .infopanel p {
    color: gray;
    padding: 5px;
    text-wrap: balance;
    font-size: large;
    margin: 0;
    text-align: center;

}

.compareAPP .infopanel h3{
    text-align: center;
    color:#E2A750;
    font-size: xx-large;
    padding-top: 5px ;
    padding-bottom: 5px;
    margin: 0;
    
}

.compareAPP .infopanel .allModsDownload,
.compareAPP .infopanel .referenceDownload {
    justify-content: center;
    width: 300px;
    background-color: #303030;
    margin: 0;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(245, 245, 245, 0.2);
}

.compareAPP .infopanel .allModsDownload {
    border-radius: 20px 20px 0px 0px;
}
.compareAPP .infopanel .compareAPP-apps {
    border-radius: 0px 0px 20px 20px;
    width: 50vw;
    min-width: 400px;
}

.compareAPP .infopanel .referenceDownload{
    border-width: 0px 1px 1px 1px;
    border-radius: 0 0 20px 20px;

}

/*
presets 
*/

.compareAPP .presets-header{
    min-width: 400px;
    height:50px;
    width: 50vw;
    padding: 0;
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 20px;
    border: solid 0px rgba(245, 245, 245, 0.3);
    border-width: 1px 1px 0 1px;
    background-color: #101010;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.compareAPP .presets-header .swtich-wrapper{
    display: flex;
    flex-direction: row;
} 

.compareAPP .presets-header .swtich-wrapper p{
    color: rgba(255, 255, 255, 0.7);
    width: 50%;
    padding: 3px 20px 3px 20px;
    border-radius: 5px;
    font-weight: bold;    
}

.compareAPP .presets-header .swtich-wrapper p:hover{
    background-color: #303030;
}

.compareAPP .presets-header .swtich-wrapper .selected {
    background-color: #202020;
    color:#E2A750;
}

.compareAPP .presets-header .header-buttons{
    width: fit-content;   
    padding: 5px;
}

.compareAPP .presets-header .header-text {
    height: 100%;
    color: rgba(255, 255, 255, 0.7)
        
}

.compareAPP .presets-header .header-buttons img{
    padding: 7px;
    filter: grayscale();    
}

.compareAPP .presets-header .header-buttons img:hover
{
    filter: None;
    background-color: #303030;
    border-radius: 15px;
}


.compareAPP .presets-header .header-buttons .toggled {
    filter: None;
}


.compareAPP .presets{
    min-width: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 20px 20px;
    height: fit-content;
    padding: 0px;
    padding-top: 10px;
    margin-top: 0;
    padding-bottom: 2vh;
    padding: 5px;
    background-color: #303030;
    border: solid 1px rgba(245, 245, 245, 0.2);
}



.presetAdd label{
    background-color: #202020;
    border: solid 1px rgba(245, 245, 245, 0.2);
    width: 50vw;
    border-radius: 20px;
    margin-top: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    
}

.presetAdd .loadButton img{
    height: 50px;
    width: 50px;
    
    padding: 5px;
    justify-content: center;
    border-radius: 25px;
    
    text-align: center;
    font-weight: bolder;
    font-size:xx-large;
}
 
.presetAdd .loadButton:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.7);
    background-color: #5a5a5a;
}