
.presetCardSmall {
    border-radius: 22px;
    min-width: 400px;
    width: 50vw;
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(245, 245, 245, 0.2);
    /*box-Shadow : 0px 0px 10px 1px #E2A750;*/
    background-color: #202020;
    margin-bottom: 5px;
    margin-top: 5px;
}

.presetCardSmall div{
    display: flex;
    flex-direction: row;
}

.presetCardSmall p {
    display: none;
}

.presetCardSmall h3{
    width: 50%;
    height: 40px;
    padding: 5px;
    padding-left: 10px;

    border-radius: 20px 0px 0 20px ;
    font-size: medium;
    display: flex; /* Usar flexbox */
    align-items: center; /* Centrar texto verticalmente */
    margin: 0;
}

.presetCardSmall .mods-button {
    width: 20%;
    border: solid 1px rgba(245, 245, 245, 0.2);
    border-width: 0px 1px 0px 1px;
    height: 40px;
    padding: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-align: center;
    justify-content: center;
}
.presetCardSmall .mods-button h5{
    width: 50%;
}

.presetCardSmall .mods-button img{
    cursor:pointer;
    width: 50%;
    height: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    filter: grayscale() opacity(.7);    
};

.presetCardSmall .mods-button img:hover{
    filter: None;     
}
.presetCardSmall .preset-buttons {
    cursor:pointer;
    /*background-color: #303030;*/
    width: 5%;
    height: 40px;
    padding: 5px;
    justify-content: center;
    border-radius: 0px 20px 20px 0px ;
    /*border: solid 1px rgba(245, 245, 245, 0.2);*/
    display: flex;
    align-items: center;
    margin: 0;
}

.presetCardSmall .preset-buttons {
    cursor:pointer;
    width: 30%;
}

.presetCardSmall .preset-buttons img{
    cursor:pointer;
    width: 30%;
    height: 70%;
    padding: 4%;
    aspect-ratio: 1;
    filter: grayscale();
}

.presetCardSmall .preset-buttons img:hover {
    filter: None;
}

.presetCardSmall table{
    padding: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 15px 15px;
    margin-bottom: 0px;
    border: solid 1px rgba(245, 245, 245, 0.2);
    border-width: 1px 0px 0px 0px;
    
    /*border: solid 2px rgba(245, 245, 245, 0.2);*/
    /*border-collapse: collapse;*/

}
.presetCardSmall table .modweight {
    text-align: right;
}


.presetCardSmall .reference .checkbox{
    margin: auto;
    cursor:pointer;
    font-size: small;
    font-weight: bold;
    margin-left: 5px;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    background-color: #101010;
}
.presetCardSmall .reference .checkbox:hover{
    background-color: #303030;
    cursor: pointer;
}
.presetCardSmall .reference .checkbox:active{
    background-color: black;
}



.presetCardSmall .merge .merge-checkbox {  
    cursor:pointer;    
    margin: auto;
    margin-left: 5px;
    width: 30px;
    height: 30px;       
    border-radius: 25px;
    padding: 10px;
    filter:grayscale();

}

.presetCardSmall .merge .merge-checkbox:hover {
    background-color: #303030;
}


