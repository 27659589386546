.nav {
    color: whitesmoke;
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;    
    background-color: #202020;
}

.nav a{
    color: white;
    padding: auto;
    margin: auto;
    text-decoration: none;
    font-weight: bolder;    
}

.nav a:hover {
    color:#E2A750;
}